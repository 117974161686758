import React, { Component } from "react";

export default class ChefWorkingImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        width="500"
        height="470"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 470"
      >
        <g>
          <title>Layer 1</title>
          <metadata id="svg_988">image/svg+xml</metadata>

          <g stroke="null" id="svg_989">
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m147.4552,193.0742c0,0 -5.75437,-2.2459 -8.90099,-2.46223c-3.14268,-0.2124 -4.37379,3.65401 0,5.58917c6.27356,2.77295 11.19802,3.65401 11.19802,3.65401l-2.29703,-6.78095"
              id="svg_674"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m224.63379,193.0742c0,0 5.75437,-2.2459 8.90099,-2.46223c3.14661,-0.2124 4.37379,3.65401 0,5.58917c-6.26963,2.77295 -11.19802,3.65401 -11.19802,3.65401l2.29703,-6.78095"
              id="svg_673"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#dad9d7"
              d="m212.0198,233.18567l-51.54942,0c-7.70921,0 -13.95524,-6.24603 -13.95524,-13.9513l0,-33.58225l79.45595,0l0,33.58225c0,7.70527 -6.24603,13.9513 -13.9513,13.9513"
              id="svg_672"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#c1bfbd"
              d="m232.55539,180.97153l-92.62454,0l0,5.08178l92.62454,0l0,-5.08178"
              id="svg_671"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#c1bfbd"
              d="m176.63218,183.37476l0,-2.07283c0,-5.30205 4.31086,-9.61291 9.60897,-9.61291c5.30205,0 9.60897,4.31086 9.60897,9.61291l0,2.07283l2.12003,0l0,-2.07283c0,-6.47023 -5.26271,-11.73294 -11.72901,-11.73294c-6.46629,0 -11.72901,5.26271 -11.72901,11.73294l0,2.07283l2.12003,0"
              id="svg_670"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m358.14465,449.4525l-328.06998,0l0,-216.1331l328.06998,0l0,216.1331"
              id="svg_669"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#58595b"
              d="m358.14465,271.13384l-328.06998,0l0,-5.76224l328.06998,0l0,5.76224"
              id="svg_668"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#929292"
              d="m324.17694,251.57763c0,3.16235 -2.56449,5.72291 -5.72291,5.72291c-3.16235,0 -5.72684,-2.56056 -5.72684,-5.72291c0,-3.16235 2.56449,-5.72291 5.72684,-5.72291c3.15841,0 5.72291,2.56056 5.72291,5.72291"
              id="svg_667"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#929292"
              d="m297.70212,251.57763c0,3.16235 -2.56056,5.72291 -5.72291,5.72291c-3.16235,0 -5.72291,-2.56056 -5.72291,-5.72291c0,-3.16235 2.56056,-5.72291 5.72291,-5.72291c3.16235,0 5.72291,2.56056 5.72291,5.72291"
              id="svg_666"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#929292"
              d="m271.23122,251.57763c0,3.16235 -2.56449,5.72291 -5.72291,5.72291c-3.16235,0 -5.72684,-2.56056 -5.72684,-5.72291c0,-3.16235 2.56449,-5.72291 5.72684,-5.72291c3.15841,0 5.72291,2.56056 5.72291,5.72291"
              id="svg_665"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#929292"
              d="m244.75639,251.57763c0,3.16235 -2.56056,5.72291 -5.72291,5.72291c-3.16235,0 -5.72291,-2.56056 -5.72291,-5.72291c0,-3.16235 2.56056,-5.72291 5.72291,-5.72291c3.16235,0 5.72291,2.56056 5.72291,5.72291"
              id="svg_664"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m328.50747,289.67133l-99.53136,0l0,-6.36403l99.53136,0l0,6.36403"
              id="svg_663"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#929292"
              d="m156.98157,251.57763c0,3.16235 -2.56449,5.72291 -5.72291,5.72291c-3.16235,0 -5.72684,-2.56056 -5.72684,-5.72291c0,-3.16235 2.56449,-5.72291 5.72684,-5.72291c3.15841,0 5.72291,2.56056 5.72291,5.72291"
              id="svg_662"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#929292"
              d="m130.50674,251.57763c0,3.16235 -2.56056,5.72291 -5.72291,5.72291c-3.16235,0 -5.72291,-2.56056 -5.72291,-5.72291c0,-3.16235 2.56056,-5.72291 5.72291,-5.72291c3.16235,0 5.72291,2.56056 5.72291,5.72291"
              id="svg_661"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#929292"
              d="m104.03585,251.57763c0,3.16235 -2.56449,5.72291 -5.72684,5.72291c-3.15841,0 -5.72291,-2.56056 -5.72291,-5.72291c0,-3.16235 2.56449,-5.72291 5.72291,-5.72291c3.16235,0 5.72684,2.56056 5.72684,5.72291"
              id="svg_660"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#929292"
              d="m77.56102,251.57763c0,3.16235 -2.56056,5.72291 -5.72291,5.72291c-3.16235,0 -5.72291,-2.56056 -5.72291,-5.72291c0,-3.16235 2.56056,-5.72291 5.72291,-5.72291c3.16235,0 5.72291,2.56056 5.72291,5.72291"
              id="svg_659"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m129.57849,224.52068l-12.06333,4.83399l-2.14363,-5.34138l12.06333,-4.83792l2.14363,5.34531"
              id="svg_658"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#58595b"
              d="m37.48495,219.17537l0,0.73159c0,7.19788 5.89204,13.08992 13.08992,13.08992l58.82203,0c7.19788,0 13.08992,-5.89204 13.08992,-13.08992l0,-0.73159l-85.00186,0"
              id="svg_657"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m143.96639,225.3506l-16.49613,0l0,-6.17523l16.49613,0l0,6.17523"
              id="svg_656"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m171.84146,217.63746l-30.1721,0l0,9.25105l30.1721,0c2.54482,0 4.62552,-2.0807 4.62552,-4.62552c0,-2.54482 -2.0807,-4.62552 -4.62552,-4.62552"
              id="svg_655"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m161.3121,289.67133l-99.53136,0l0,-6.36403l99.53136,0l0,6.36403"
              id="svg_654"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m333.89212,209.71586c0,0 -17.52664,0 -28.13074,0c-2.27343,0 -6.15557,3.15055 -8.38966,2.82409c-1.25078,-0.18486 -3.70907,-2.82409 -3.70907,-2.82409c-1.67557,0 -3.04042,-1.10525 -3.04042,-2.45829c0,-1.35304 1.36484,-2.45829 3.04042,-2.45829l40.22947,0c1.67557,0 3.04042,1.10525 3.04042,2.45829c0,1.35304 -1.36484,2.45829 -3.04042,2.45829"
              id="svg_653"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#dad9d7"
              d="m252.87072,198.59257l0,24.85039c0,5.41218 4.42886,9.84104 9.84497,9.84104l24.81499,0c5.41218,0 9.84104,-4.42886 9.84104,-9.84104l0,-24.85039l-44.501,0"
              id="svg_652"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#c1bfbd"
              d="m297.93418,201.16886l-45.33485,0c-1.41991,0 -2.58022,-1.16031 -2.58022,-2.57629c0,-1.41991 1.16031,-2.58022 2.58022,-2.58022l45.33485,0c1.41991,0 2.58022,1.16031 2.58022,2.58022c0,1.41598 -1.16031,2.57629 -2.58022,2.57629"
              id="svg_651"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#f9aa6d"
              d="m330.05718,198.59257c-3.9136,3.68941 -12.42913,11.11935 -11.54021,13.97097c0.88892,2.85162 11.56381,4.35806 16.0084,3.37474c4.44853,-0.98332 14.23056,-7.48108 15.56394,-6.52136c1.33731,0.96365 33.35412,9.75844 33.35412,9.75844l4.57439,-19.32021c0,0 -48.12354,-10.54903 -57.96064,-1.26258"
              id="svg_650"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m412.40015,425.31796c0,0 -23.93,11.61887 -30.54576,15.83927c-6.61969,4.2204 -4.08273,8.29526 -4.08273,8.29526l48.37133,0c0,0 0.68832,-18.83642 -0.67259,-22.90735c-1.36091,-4.07487 -13.07025,-1.22718 -13.07025,-1.22718"
              id="svg_649"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m446.124,425.31796c0,0 -23.92607,11.61887 -30.54576,15.83927c-6.61969,4.2204 -4.08273,8.29526 -4.08273,8.29526l48.37527,0c0,0 0.68439,-18.83642 -0.67652,-22.90735c-1.36091,-4.07487 -13.07025,-1.22718 -13.07025,-1.22718"
              id="svg_648"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#58595b"
              d="m459.87077,449.4525l-48.85119,0l0,-2.72182l48.85119,0l0,2.72182"
              id="svg_647"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m426.31212,429.56196c0.15733,-9.49491 -4.84972,-70.12624 -4.84972,-79.34189c0,-5.21945 3.55567,-34.2981 4.84185,-45.43712l-30.56936,-3.66187c-1.04232,18.43523 -5.33745,30.3609 -2.58416,60.2852c0.58999,6.42303 8.25986,59.28222 8.78299,68.15567l24.3784,0"
              id="svg_646"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m461.09008,429.56196l-0.94792,-123.3945l-28.19367,0l5.63244,123.3945l23.50915,0"
              id="svg_645"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#f9aa6d"
              d="m406.80705,81.03898c1.05805,-4.05913 2.1515,-6.0179 6.44663,-9.00325c4.29906,-2.98535 21.09412,-5.9589 29.23598,0.94398c8.1458,6.90682 4.93232,19.67421 3.60287,24.04407c-1.50644,4.95592 -4.2322,11.09968 -5.17225,17.95537c-0.42479,3.09942 2.48976,10.0377 3.44161,11.81554l0.0118,0.01967c-3.33541,4.02373 -9.47524,5.61277 -14.39576,6.09263c-2.78869,0.2714 -5.18405,0.02753 -7.44568,-0.51526c0.31859,-0.40906 0.59786,-0.84565 0.77485,-1.34124c0.62146,-1.75424 0.31466,-9.18025 -1.21931,-10.89909c-2.1751,-2.44649 -4.74352,-2.27736 -7.92554,-1.62837c-4.21253,0.85745 -3.26461,-4.58226 -5.91564,-5.46724c-1.73064,-0.58212 -0.60966,-1.47498 -0.17306,-2.35996c-0.28713,-0.60179 -1.61264,-0.91645 -1.88797,-1.44351c-0.67259,-1.28618 0.81419,-1.76604 0.60572,-2.43076c-0.52312,-1.67164 -5.26665,-1.7385 -4.83005,-3.53601c0.75125,-3.10728 6.19883,-9.15665 5.43578,-11.19015c-1.54184,-4.10633 -1.59297,-7.20181 -0.58999,-11.05642"
              id="svg_644"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m407.14531,79.83933c0,0 5.63637,-2.17116 13.29051,3.63827c4.89692,3.71694 5.06605,8.08287 11.59528,9.8961c5.62457,1.56151 4.60979,5.56557 4.60979,5.56557l4.83792,13.62877c0,0 8.49586,-7.20181 9.22351,-23.20628c0.70012,-15.39481 -3.9372,-17.25132 -3.9372,-17.25132c0,0 -8.29133,-6.43876 -24.86219,-5.16438c-9.32971,0.71585 -13.72317,8.40539 -14.75762,12.89325"
              id="svg_643"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#f9aa6d"
              d="m430.65839,93.86144c0,0 0.18093,-5.06998 4.98345,-4.47606c4.80252,0.59786 5.36104,7.39848 0.118,11.41434c-5.24698,4.0198 -5.10145,-6.93829 -5.10145,-6.93829"
              id="svg_642"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m463.35958,60.86918l-43.54521,-16.1264l-13.04272,35.21848l43.54128,16.1264l13.04665,-35.21848"
              id="svg_641"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m466.73039,312.26796c0.01967,-18.34476 0.0354,-113.3096 -1.83684,-138.72638c-2.59989,-35.34435 -20.53166,-46.74689 -20.53166,-46.74689l-21.82964,5.59704c0,0 -11.07608,8.73972 -15.41448,20.10293c-5.23518,13.71137 -9.21171,35.15162 -9.94724,38.96296c-1.17998,6.1241 -4.55079,98.44184 -5.35318,120.81034l74.91303,0"
              id="svg_640"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m420.69148,139.49915c-2.07283,2.49369 -2.47796,47.39982 -8.66106,51.95848c-8.0868,5.96677 -37.73971,3.772 -37.73971,3.772l-4.59799,24.4256c0,0 19.50114,5.56557 27.93407,5.69537c12.94439,0.19666 26.3411,1.47498 37.43685,-10.70242c10.98169,-12.0476 21.74704,-54.3027 20.81092,-61.33932c-0.93612,-7.04055 -9.82924,-23.82774 -9.82924,-23.82774"
              id="svg_639"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#dad9d7"
              d="m413.35987,141.17079c0,0 5.09752,-13.64844 15.11555,-17.43618c8.32673,-3.14661 20.46086,-3.14661 20.46086,-3.14661l0,10.29336c0,0 -10.75749,-2.89882 -16.59053,-1.00298c-7.63054,2.47796 -18.98589,11.29241 -18.98589,11.29241"
              id="svg_638"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#58595b"
              d="m426.14299,449.4525l-48.84726,0l0,-2.72182l48.84726,0l0,2.72182"
              id="svg_637"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#dad9d7"
              d="m395.2275,225.19327c0,0 19.48541,4.51539 29.66078,-3.01288c10.17537,-7.53221 20.81486,-25.26338 25.88484,-45.70851c5.07392,-20.44513 5.10145,-23.16302 5.10145,-23.16302c0,0 1.8565,28.56733 -5.10145,46.5463c-8.2992,21.45598 -17.68004,27.60368 -27.97734,31.42288c-15.63474,5.79764 -27.92227,2.00597 -27.92227,2.00597l0.35399,-8.09073"
              id="svg_636"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#dad9d7"
              d="m464.42943,56.14532c-7.62661,-7.05235 -17.20018,-12.02007 -26.97829,-15.34368c-7.65807,-2.60382 -25.94384,-3.30395 -30.30583,3.94113c-7.73674,12.84999 8.14973,12.20493 26.36076,22.03417c6.2657,3.38654 12.77919,10.48216 21.24358,12.57073c6.27356,1.54577 14.0457,0.46019 16.46073,-6.43483c2.28523,-6.53709 -2.2459,-12.57073 -6.78095,-16.76752"
              id="svg_635"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#58595b"
              d="m342.80097,435.41859l-128.11442,0l0,-132.46855l128.11442,0l0,132.46855"
              id="svg_634"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#58595b"
              d="m279.32589,23.5582l-228.99095,0l0,-4.64519l228.99095,0l0,4.64519"
              id="svg_633"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#a5a5a4"
              d="m437.13255,47.4764c0,0 -6.40336,2.72969 -9.45558,5.63637c-3.05222,2.90669 -0.74732,8.09467 2.98142,4.43279c3.72874,-3.66187 6.47416,-10.06917 6.47416,-10.06917"
              id="svg_632"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#a5a5a4"
              d="m454.21866,54.78835c0,0 2.84769,8.10253 1.8801,13.97097c-0.96758,5.86844 1.61264,10.1911 3.9136,5.70324c4.57832,-8.92459 -5.7937,-19.67421 -5.7937,-19.67421"
              id="svg_631"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#a5a5a4"
              d="m427.67697,46.00143c-1.21931,0.25566 -8.54306,-0.0472 -11.89814,2.39143c-3.35508,2.44256 2.74542,6.71016 5.95104,3.66187c3.20168,-3.05222 5.9471,-6.0533 5.9471,-6.0533"
              id="svg_630"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#58595b"
              d="m173.01357,435.41859l-128.11442,0l0,-132.46855l128.11442,0l0,132.46855"
              id="svg_629"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m249.58251,87.08835l-4.90872,0l0,-25.24765l4.90872,0l0,25.24765"
              id="svg_628"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m251.69468,63.79947l0,-31.03349c0,-2.50943 -2.05317,-4.56652 -4.56652,-4.56652c-2.50943,0 -4.56259,2.0571 -4.56259,4.56652l0,31.03349l9.12912,0"
              id="svg_627"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m247.12815,36.59305l0,0c-1.01872,0 -1.8447,-0.82599 -1.8447,-1.8447l0,-21.16492c0,-1.01872 0.82599,-1.84077 1.8447,-1.84077c1.01872,0 1.8447,0.82205 1.8447,1.84077l0,21.16492c0,1.01872 -0.82599,1.8447 -1.8447,1.8447"
              id="svg_626"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#58595b"
              d="m276.99346,114.14137c0,16.49219 -13.36918,29.86137 -29.86531,29.86137c-16.49219,0 -29.86137,-13.36918 -29.86137,-29.86137c0,-16.49219 13.36918,-29.86531 29.86137,-29.86531c16.49613,0 29.86531,13.37311 29.86531,29.86531"
              id="svg_625"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#484849"
              d="m268.09247,114.14137c0,11.57561 -9.38478,20.96432 -20.96432,20.96432c-11.57561,0 -20.96432,-9.38871 -20.96432,-20.96432c0,-11.57954 9.38871,-20.96432 20.96432,-20.96432c11.57954,0 20.96432,9.38478 20.96432,20.96432"
              id="svg_624"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m88.11398,87.08835l-4.91265,0l0,-25.24765l4.91265,0l0,25.24765"
              id="svg_623"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m90.22221,63.79947l0,-31.03349c0,-2.50943 -2.05317,-4.56652 -4.56652,-4.56652c-2.50943,0 -4.56259,2.0571 -4.56259,4.56652l0,31.03349l9.12912,0"
              id="svg_622"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m85.65962,36.59305l-0.00393,0c-1.01872,0 -1.84077,-0.82599 -1.84077,-1.8447l0,-21.16492c0,-1.01872 0.82205,-1.84077 1.84077,-1.84077l0.00393,0c1.01478,0 1.84077,0.82205 1.84077,1.84077l0,21.16492c0,1.01872 -0.82599,1.8447 -1.84077,1.8447"
              id="svg_621"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#58595b"
              d="m126.14475,124.76512c0,22.36063 -18.1245,40.48906 -40.48513,40.48906c-22.36456,0 -40.48906,-18.12843 -40.48906,-40.48906c0,-22.36063 18.1245,-40.48906 40.48906,-40.48906c22.36063,0 40.48513,18.12843 40.48513,40.48906"
              id="svg_620"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#484849"
              d="m114.08141,124.76512c0,15.69767 -12.72806,28.4218 -28.4218,28.4218c-15.69767,0 -28.42573,-12.72412 -28.42573,-28.4218c0,-15.69767 12.72806,-28.4218 28.42573,-28.4218c15.69374,0 28.4218,12.72412 28.4218,28.4218"
              id="svg_619"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m176.14839,87.08835l-4.90872,0l0,-25.24765l4.90872,0l0,25.24765"
              id="svg_618"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#363536"
              d="m178.26055,63.79947l0,-31.03349c0,-2.50943 -2.05317,-4.56652 -4.56652,-4.56652c-2.50943,0 -4.56259,2.0571 -4.56259,4.56652l0,31.03349l9.12912,0"
              id="svg_617"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#ffffff"
              d="m173.69403,36.59305l0,0c-1.01872,0 -1.8447,-0.82599 -1.8447,-1.8447l0,-21.16492c0,-1.01872 0.82599,-1.84077 1.8447,-1.84077c1.01872,0 1.8447,0.82205 1.8447,1.84077l0,21.16492c0,1.01872 -0.82599,1.8447 -1.8447,1.8447"
              id="svg_616"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#58595b"
              d="m208.78665,119.36868c0,19.38315 -15.70947,35.09262 -35.09262,35.09262c-19.37921,0 -35.08869,-15.70947 -35.08869,-35.09262c0,-19.37921 15.70947,-35.09262 35.08869,-35.09262c19.38315,0 35.09262,15.71341 35.09262,35.09262"
              id="svg_615"
            />
            <path
              stroke="null"
              fill-rule="nonzero"
              fill="#484849"
              d="m198.33202,119.36868c0,13.60518 -11.03282,24.63406 -24.63799,24.63406c-13.60518,0 -24.63406,-11.02888 -24.63406,-24.63406c0,-13.60518 11.02888,-24.63406 24.63406,-24.63406c13.60518,0 24.63799,11.02888 24.63799,24.63406"
              id="svg_614"
            />
          </g>
        </g>
      </svg>
    );
  }
}
