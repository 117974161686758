import React, { Component } from "react";

export default class ExperienceImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        width={170}
        height={450}
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 170 450"
      >
        <title>{"Layer 1"}</title>
        <g stroke="null">
          <path
            fill="#a5a5a4"
            d="M73.415 92.373s5.973-4.3 14.034-4.3 13.366 4.3 13.366 4.3l-11.254 9.597-16.146-9.597"
          />
          <path
            fill="#dad9d7"
            d="M116.894 27.054C105.585 10.968 76.459 7.209 60.676 18.4c-9.071 6.436-8.853 16.221-4.663 22.64 10.241 15.69 23.02 15.39 31.942 15.943 9.32.582 22.505.029 29.648-7.645 5.675-6.1 3.735-15.967-.709-22.284"
          />
          <path
            fill="#a53020"
            d="M48.707 411.099s-6.45 4.845-13.014 9.44c-6.566 4.596-6.415 12.25-6.415 12.25h42.276s1.296-17.537-3.452-21.69H48.707"
          />
          <path fill="#85251b" d="M71.682 429.985H29.278v2.804h42.404v-2.804" />
          <path
            fill="#a53020"
            d="M132.601 411.099s6.45 4.845 13.016 9.44c6.565 4.596 6.415 12.25 6.415 12.25h-42.278s-1.294-17.537 3.454-21.69H132.6"
          />
          <path
            fill="#85251b"
            d="M109.625 429.985h42.407v2.804h-42.407v-2.804"
          />
          <path
            fill="#f9aa6d"
            d="M27.262 207.762s-.767 9.244 2.303 13.639c3.068 4.394 5.37 6.313 5.37 6.313s-5.158-.767-7.758 0c-2.6.766-1.673 3.451 4.769 4.603 6.442 1.148 7.798 1.919 11.53-3.07 3.735-4.985 1.817-23.785 1.817-23.785l-5.371-5.751-12.66 8.051M147.613 207.762s.767 9.244-2.3 13.639c-3.07 4.394-5.371 6.313-5.371 6.313s5.158-.767 7.756 0c2.601.766 1.674 3.451-4.767 4.603-6.442 1.148-7.8 1.919-11.532-3.07-3.733-4.985-1.815-23.785-1.815-23.785l5.37-5.751 12.66 8.051"
          />
          <path
            fill="#ed7a31"
            d="M76.94 87.208s.52 7.457-.633 11.79c-.68 2.566 12.28 13.958 12.28 13.958s9.309-7.633 10.03-13.958c-.388-5.055-1.133-11.79-1.133-11.79H76.94"
          />
          <path
            fill="#f9aa6d"
            d="M68.573 62.14s-2.623-1.39-4.319 2.005c-1.696 3.39 1.433 8.801 4.363 8.953 2.931.156-.044-10.958-.044-10.958M108.333 61.98s2.796-.988 3.978 2.616c1.182 3.603-2.704 8.498-5.625 8.223-2.921-.279 1.647-10.839 1.647-10.839"
          />
          <path
            fill="#f9aa6d"
            d="M107.902 65.949c0 14.593-9.008 26.424-20.12 26.424-11.111 0-20.119-11.83-20.119-26.424s9.008-26.42 20.119-26.42c11.112 0 20.12 11.826 20.12 26.42"
          />
          <path
            fill="#363536"
            d="m66.792 61.923 2.232 1.578 4.39-6.35h-6.572l-.05 4.772M109.022 61.923l-2.234 1.578-4.39-6.35h6.574l.05 4.772M43.815 249.457 39.85 416.379h35.6l11.717-158.855L109.12 416.38h34.402L130.21 249.457l-17.68-22.26H66.842l-23.027 22.26"
          />
          <path
            fill="#fff"
            d="M136.353 111.886c-7.914-6.063-37.736-12.888-37.736-12.888s-10.662 10.814-11.572 12.572c-1.308-2.041-10.738-12.572-10.738-12.572s-28.93 6.518-36.457 12.167c-11.016 8.272-29.564 38.935-37.067 51.803-2.07 3.546-2.09 10.827-.96 13.41 4.554 10.391 21.314 39.087 21.314 39.087l16.713-12.462-14.729-31.725 18.498-18.857.196 97.036h86.394l.264-97.036 18.713 19.705-16.43 29.462 16.714 13.877s16.386-25.556 21.528-34.951c3.222-5.891 2.116-14.615-.338-19.005-7.52-13.458-24.058-41.773-34.307-49.623"
          />
          <path
            fill="#dad9d7"
            d="M109.12 249.457H63.775V111.57h45.345v137.887"
          />
          <path fill="#fff" d="M105.71 249.457H59.819V114.379h45.893v135.078" />
          <path
            fill="#a5a5a4"
            d="M113.496 29.169s-4.39 1.189-6.23 2.607c-1.842 1.414-3.967 6.797-3.967 6.797s4.107-3.681 7.08-5.383c2.976-1.7 3.117-4.021 3.117-4.021M59.818 26.107s2.495 5.427 6.092 7.083c3.594 1.66 7.013 4.534 7.013 4.534s-.924-4.816-4.464-7.51c-3.54-2.689-8.64-4.107-8.64-4.107M102.024 21.27s-.187 5.964-2.854 8.891c-2.665 2.927-4.697 6.907-4.697 6.907s-1.022-4.796 1.192-8.653c2.213-3.858 6.359-7.145 6.359-7.145M72.923 18.179s1.099 5.177 3.437 8.498c2.336 3.32 4.278 7.194 4.278 7.194s-.09-7.785-1.223-9.916c-2.317-4.362-6.492-5.776-6.492-5.776"
          />
          <path
            fill="#fff"
            d="M109.12 59.05V37.486s-6.53-8.318-21.336-8.318c-14.936 0-20.992 8.318-20.992 8.318v21.562h42.328"
          />
          <path
            fill="#dad9d7"
            d="m72.923 99.797.492-7.424s14.197 10.13 13.63 19.197c-8.321-5.665-14.122-11.773-14.122-11.773"
          />
          <path
            fill="#dad9d7"
            d="m101.307 99.797-.492-7.424s-14.197 10.13-13.63 19.197c8.322-5.665 14.122-11.773 14.122-11.773"
          />
          <path
            fill="#363536"
            d="M75.117 120.577a2.367 2.367 0 0 1-2.365 2.365 2.365 2.365 0 1 1 2.365-2.365M102.506 120.577a2.366 2.366 0 0 1-2.363 2.365 2.364 2.364 0 1 1 2.363-2.365M75.117 157.114a2.364 2.364 0 1 1-4.73 0 2.368 2.368 0 0 1 2.365-2.365 2.367 2.367 0 0 1 2.365 2.365M102.506 157.114a2.364 2.364 0 1 1-4.729 0 2.367 2.367 0 0 1 2.366-2.365 2.366 2.366 0 0 1 2.363 2.365M75.117 190.487a2.364 2.364 0 1 1-4.73 0 2.368 2.368 0 0 1 2.365-2.365 2.367 2.367 0 0 1 2.365 2.365M102.506 190.487a2.364 2.364 0 1 1-4.729 0 2.367 2.367 0 0 1 2.366-2.365 2.366 2.366 0 0 1 2.363 2.365M75.117 227.03a2.367 2.367 0 0 1-2.365 2.364 2.365 2.365 0 1 1 2.365-2.365M102.506 227.03a2.366 2.366 0 0 1-2.363 2.364 2.364 2.364 0 1 1 2.363-2.365"
          />
          <path
            fill="#dad9d7"
            d="m19.957 218.24 23.767-13.962-7.698-22.358s-24.141 15.5-16.07 36.32M154.58 218.24l-23.767-13.962 7.697-22.358s24.142 15.5 16.07 36.32"
          />
        </g>
      </svg>
    );
  }
}
