import React, { Component } from "react";

export default class CookingSchoolImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={480}
        height={430}
        xmlSpace="preserve"
        viewBox="0 0 480 430"
      >
        <g>
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#635a57",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.512 509.236)"
            d="M3223.4 2647.15H671.551c-34.965 0-63.567-27.86-64.485-62.82L561.891 863.551H3333.06l-45.17 1720.779c-.92 34.96-29.52 62.82-64.49 62.82"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#8cc6dd",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.555 509.312)"
            d="M3201.1 2536.47H664.055L625.742 927.41H3239.41l-38.31 1609.06"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#9bd8ea",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.593 509.312)"
            d="M2740.38 2536.47H1624.19L1104.12 927.41h1149.9l486.36 1609.06"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#c1b3b2",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.519 512.328)"
            d="M3341.38 884.84H545.059c-24.821 0-42.168-21.899-33.871-42.758l20.234-50.852c5.09-12.781 18.656-21.332 33.871-21.332H3321.15c15.21 0 28.78 8.551 33.86 21.332l20.24 50.852c8.3 20.859-9.05 42.758-33.87 42.758"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -56.154 508.206)"
            d="M1141.15 2128.08c-4.89 5.63-10.46 4.92-15.63.43-19.74-17.13.82-66.45 22.86-91.83 12.04-13.87 24.65-22.7 38.98-10.25 16.69 14.47 21.52 35.97 21.72 57.59l7.99 6.93c-.27-22.48 12.55-62.88-29.05-98.98-19.5-16.94-41.58-17.15-65.65 10.59-29.79 34.3-54.36 92.51-8.77 132.09 12.69 11.01 28.71 19.16 39.92 6.22 2.25-2.58 3.85-4.9 5.57-8.76-1.79-.32-4.29-1.25-7.11-3.7-2.11-1.83-4.05-4.35-6.29-7.94-1.54 3.21-2.9 5.72-4.54 7.61"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -55.86 507.96)"
            d="M1265.9 2162c-7.47-5.05-14.48-1.9-18.67 4.29-5.05 7.47-6.07 18.43-.85 25.71-6.52 7.99-12.67 10.97-19.88 6.09-24.24-16.37.99-75.94 14.93-96.56 10.45-15.47 20.45-14.72 30.51-7.92 15.72 10.62 7.77 41.3-6.04 68.39zm6.09 33.78c2.59-.5 5.43.3 9.05 2.73 12.11 8.2 11.6 20.62 18.53 30.93l4.69-4.71c-1.2-15.47-8.86-33.02-21.03-47.25 30.12-55.14 19.27-99.65-6.51-117.07-27.59-18.63-47.28-15.05-68.02 15.63-42.85 63.42-29.09 112.52 6.49 136.57 24.49 16.55 43.56 1.64 56.8-16.83"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -55.461 507.75)"
            d="M1383.08 2227.21c-8.03-4.11-14.61-.14-18.02 6.51-4.11 8.02-3.8 19.02 2.25 25.62-5.51 8.71-11.24 12.42-19 8.44-26.03-13.33-8.16-75.5 3.19-97.65 8.51-16.61 18.53-17.07 29.33-11.54 16.89 8.66 12.69 40.06 2.25 68.62zm10.11 32.8c2.52-.81 5.44-.36 9.31 1.62 13.02 6.67 14.01 19.07 22.12 28.46l4.09-5.24c-3.05-15.2-12.77-31.71-26.56-44.37 23.25-58.35 7.11-101.23-20.58-115.41-29.62-15.19-48.74-9.25-65.62 23.7-34.9 68.11-15.33 115.2 22.89 134.78 26.3 13.47 43.44-3.61 54.35-23.54"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -54.983 507.496)"
            d="m1536.58 2360.6-44.7-61.72c2.42 1.54 4.65 2.68 7.28 3.64 15.78 5.78 40.1-4.56 47.15-23.85.85-2.34 1.52-5.08 1.79-7.62l1.99-18.16c.3-3.54.89-6.96 1.96-9.89 2.34-6.42 7.16-9.64 18.56-5.48 11.69 4.28 15.95 24.39 24.22 38l9.94 3.63c-8.53-18.36-22.4-67.48-45.19-75.81-22.51-8.22-40.17-3.4-47.97 17.93-1.71 4.68-3.04 10.16-3.61 16.24l-1.69 14.62c-.38 2.84-1.05 5.58-1.91 7.92-3.84 10.52-11.85 15.21-19.74 12.33-7.31-2.67-11.72-6.92-16.36-13.26l12.07-84.71-42.09-15.38-30.23 214.24 41.12 21.65 15.45-110.31c15.52 25.55 40.29 64.74 40.39 64.45l31.57 11.54"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -54.595 507.327)"
            d="m1612.45 2379.2 4.74-108.03c.26-3.47.52-6.93 1.21-9.65 1.71-6.62 5.97-10.68 18.02-7.56 12.05 3.11 23.36 24.02 32.92 36.78l10.24 2.64c-10.28-17.43-33.98-66.3-57.47-72.36-23.2-5.98-40.31.53-46.13 23.13-1.18 4.51-1.89 9.79-2.16 15.82l-4.75 108.03zm-30.34 60.28c13.26 3.43 26.9-4.63 30.32-17.88 3.42-13.25-4.7-26.59-17.96-30.02-13.25-3.42-26.52 4.4-29.94 17.66-3.42 13.25 4.32 26.82 17.58 30.24"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -54.193 507.368)"
            d="M1778.46 2302.54c-.38-3.53-.45-7 .05-10.08 1.08-6.75 5.2-10.81 17.17-8.89 12.29 1.97 15.37 12.23 26.08 24.03l10.43 1.67c-11.86-16.39-29.87-53.3-53.82-57.16-23.96-3.84-39.76 4.33-43.46 27.37-.79 4.91-.96 9.92-.67 15.96l2.8 57.14c.17 2.87.03 5.69-.36 8.15-1.78 11.04-8.75 17.17-17.04 15.85-7.37-1.19-12.55-4.23-18.31-9.57l-6.19-128.25-44.22-7.1 7.9 158.77 44.22 7.1-1.02-17.18c11.11 11.24 24.06 20.88 37.57 23.05 16.59 2.66 38.17-12.15 41.48-32.72.39-2.46.79-4.92.57-7.47l-3.18-60.67"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.708 507.423)"
            d="M1854.6 2205.75c6.22.22 13.96 9.85 17.02 28.33l1.51 9.4c-17.47-7.49-29.93-15.73-29.48-27.87.16-4.35 4.11-10.13 10.95-9.86zm-13.66 100.08c.55-14.93 9.74-19.26 23.42-18.76 6.23.24 12.59 5.14 18.26 11.57l15.17 86.83c-5.32 9.15-12.94 12.92-18.22 12.72-30.17-1.11-39.62-65.61-38.63-92.36zm107.32 112.65-24.14-138.85c19.53 11 36.74 25.65 46.13 49.36l-.33-24.62c-3.29-20.37-24.64-32.68-48.88-42.6l-4.36-25.08c-7.69-44.82-33.5-54.18-54.03-54.94-18.97-.71-35.19 8.66-35.94 28.87-.95 25.51 21.38 36.61 48.99 47.91l2.59 14.11c-9.02-8.74-20.9-15.72-35.22-16.25-29.85-1.11-39.49 23.76-40.16 42.12-1.6 42.91 12.25 114.74 72.59 116.96 11.2.43 20.45-5.46 25.11-13.7l2.87 15.05 44.78 1.66"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.029 507.323)"
            d="M2132.95 2380.9c.88 7.41-3.38 11.05-10.17 11.88-25.94 3.1-48.66-45.24-52.66-78.6-2.19-18.22-.25-33.49 18.58-35.75 21.93-2.63 41.09 8.23 57.23 22.6l10.5-1.26c-16.83-14.91-38.13-51.52-92.8-44.96-25.63 3.07-40.61 19.28-36.24 55.72 5.41 45.09 32.01 102.38 91.92 95.19 16.69-2.01 33.46-8.4 31.42-25.39-.4-3.39-1.04-6.14-2.76-10-1.43 1.11-3.79 2.33-7.5 2.78-2.78.32-5.94.09-10.11-.67 1.34 3.29 2.29 5.99 2.59 8.46"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -52.678 507.277)"
            d="m2254.44 2443.76-68.43-157.57c-1.84-4.39-3-7.01-3.5-9.44-1.36-6.71 1.07-11.98 12.97-14.38 12.19-2.47 31.51 11.4 45.68 18.68l10.37-2.1c-16.86-11.18-59.54-44.77-83.33-39.94-23.48 4.75-35.72 18.02-31.08 40.89.93 4.58 2.58 9.64 4.98 15.19l65.97 151.71 46.37-3.04"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -52.308 507.486)"
            d="M2255.62 2266.83c-5.43-17.86 3.4-24.12 10.85-26.38 6.84-2.08 15.38 2.49 23.57 9.09 1.2 2.9 2.8 5.99 4.77 9.3l40.63 73.84c-3.49 5.61-8.79 8.52-12.96 9.79-22.33 6.77-54.57-35.15-66.86-75.64m134.25 73.71-52.18-94.72c-1.59-3.1-3.18-6.2-4-8.87-1.99-6.56-.45-12.22 11.46-15.84 11.91-3.61 32.45 8.36 47.26 14.27l10.12-3.08c-17.85-9.53-63.52-38.93-86.75-31.86-19.36 5.87-30.29 16.99-29.45 33.65-12.65-9.49-29.75-17.63-47.92-12.12-28.58 8.68-30.76 31.47-25.43 49.03 15.55 51.22 52.63 106.95 112.18 88.87 7.15-2.17 13.69-7.41 17.69-13.51l4.14 7.2 42.88-13.02"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.905 507.634)"
            d="M2389.39 2218.13c-3.41 1.51-6.44 3.88-9.1 7.1-7.43-16.79-1.6-28.21 13.47-34.88 9.68-4.28 28.91 1.49 38.6 23.4 7.8 17.64 1.67 38.39 16.84 74.19-10.72-11.92-22.76-28.37-35.31-39.82 5.82-5.3 6.12-11.54 2.34-20.09-4.79-10.81-16.88-14.3-26.84-9.9m108.21 83.1-1.76-3.98c-20.77-46.94-7.72-69.73-22.45-103.01-.88-2-1.75-3.98-2.92-5.85 11.11 3.59 21.49 7.83 26.67 10.31l3.12-12.94c-10.74-2.75-26.52-7.66-42.18-12.3-14.33-12.37-35.33-16.01-61.78-4.3-32.72 14.48-44.49 30.91-32.16 58.79 10.07 22.76 26.1 31.31 37.86 27.13 18.42 13.96 36.17 36.39 50.57 55.87l45.03-9.72"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.566 507.803)"
            d="M2488.51 2172.65c-3.25 1.83-6.04 4.49-8.36 7.96-9.06-15.97-4.39-27.93 9.95-36.06 9.2-5.22 28.91-1.38 40.74 19.47 9.52 16.77 5.48 38.02 24.13 72.17-11.86-10.8-25.47-25.98-39.11-36.13 5.27-5.86 4.95-12.11.34-20.23-5.84-10.29-18.22-12.56-27.69-7.18m115.95 71.96-2.15-3.79c-25.33-44.66-14.61-68.65-32.57-100.31-1.08-1.89-2.15-3.79-3.49-5.52 11.41 2.45 22.17 5.66 27.57 7.61l1.81-13.2c-10.95-1.66-27.15-4.99-43.2-8.04-15.48-10.9-36.74-12.44-61.91 1.84-31.13 17.66-41.22 35.2-26.17 61.72 12.28 21.65 29.09 28.57 40.38 23.25 19.72 12.06 39.61 32.62 55.88 50.58l43.85-14.14"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.179 508.04)"
            d="M2702.23 2154.29c-14.05 10.3-46.39-9.6-68.94-29.33 23.26-16.67 54.11-8.8 67.43 8.83 3.85 5.27 9.04 14.98 1.51 20.5m-83.69-86.81c12.3-9.02 44.05-9.91 66.45 2.22l11.29-8.27c-33.25-15.38-75.92-23.06-108.3.68-24.1 17.65-23.72 45.54-7.9 67.11 23.36 31.88 77.93 77.92 123.62 44.44 20.57-15.08 25.87-32.08 12.99-49.65-22.44-30.61-68.2-24.09-94.24-9.63-3.45-3.64-6.46-7.23-8.67-10.23-12.14-16.57-5.03-29.5 4.76-36.67"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#445d6d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -50.898 508.275)"
            d="M2681.95 2043.05c-2.75 2.52-4.87 5.74-6.36 9.63-12.41-13.53-10.54-26.23 1.62-37.37 7.8-7.14 27.87-7.81 44.06 9.84 13.04 14.22 13.85 35.85 39.68 64.93-13.97-7.87-30.64-19.61-46.2-26.45 3.82-6.89 2.1-12.9-4.2-19.78-8-8.72-20.57-8.16-28.6-.8m129.11 44.15-2.94-3.22c-34.69-37.84-29.61-63.6-54.21-90.44-1.47-1.61-2.94-3.21-4.64-4.6 11.67-.16 22.87.55 28.57 1.23l-1.18-13.25c-11.05.83-27.58 1.22-43.91 1.83-17.53-7.15-38.59-3.88-59.91 15.68-26.38 24.18-32.29 43.52-11.68 65.99 16.82 18.35 34.75 21.33 44.55 13.61 21.92 7.33 45.91 22.91 65.79 36.78l39.56-23.61"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#bab6b5",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -49.107 507.729)"
            d="m3309.29 1768.86-21.4 815.47 21.4-815.47m-21.4 815.47c-.9 34.27-28.42 61.73-62.45 62.79 34.03-1.06 61.55-28.51 62.45-62.79"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#48413e",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -50.778 507.731)"
            d="M3309.29 1768.86c-29.68 4.96-59.85 11.27-90.36 18.91l-17.83 748.7h-931.52c-17.55 37.18-32.06 74.18-43.37 110.68h997.19c.68 0 1.36-.01 2.04-.03 34.03-1.06 61.55-28.52 62.45-62.79l21.4-815.47"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#668e9d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -50.854 507.886)"
            d="M3218.93 1787.77c-143.9 36.07-295.53 101.97-441.57 196.99l.48 5.41c-1.97-.23-4.61-.48-7.7-.68-4.19 2.75-8.36 5.53-12.54 8.33 20.48 25.34 17.67 50.34 50.52 86.16l2.94 3.22-39.56 23.61c-19.88-13.87-43.87-29.45-65.79-36.78-3.29 2.59-7.51 3.98-12.29 3.98-8.31 0-18.33-4.19-28.23-13.5-17.26 13.39-34.1 26.99-50.52 40.82 2.1 2.74 4.77 5.88 7.78 9.05 12.57-6.98 29.73-12.11 46.64-12.11 18.13 0 35.99 5.9 47.6 21.74 12.88 17.57 7.58 34.57-12.99 49.65-11.25 8.25-23.04 11.67-34.77 11.67-12.98 0-25.89-4.2-37.92-10.69l109.37 361.83h-470.8 931.52l17.83-748.7"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#719ba6",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.647 507.3)"
            d="M2577.43 2137.66c-2.07 1.86-4.14 3.72-6.2 5.59 15.88 30.34 6.48 54.2 31.08 97.57l2.15 3.79-43.85 14.14c-16.27-17.96-36.16-38.52-55.88-50.58-.51.24-1.03.45-1.55.64-7.29 7.47-14.46 14.98-21.51 22.52 1.42 18.27 1.7 37.74 14.17 65.92l1.76 3.98-45.03 9.72c-5.97-8.07-12.51-16.65-19.44-24.93-15.13 17.97-29.6 36.07-43.38 54.3l.12.22-.37.11c-48.81 64.56-89.06 130.45-119.92 195.82h470.8l-109.37-361.83c-15.88-8.58-30.22-21.16-41.14-33.38-3.38-1.09-7.67-2.35-12.44-3.6m-41.72 38.79c-5.41 5.22-10.75 10.45-16.05 15.72-.3 2.78-1.53 5.37-3.8 7.89 13.64 10.15 27.25 25.33 39.11 36.13-14.35-26.26-15.27-44.9-19.26-59.74m-91.83 96.96c-1.19 1.38-2.39 2.77-3.57 4.15 3.05 3.66 6.02 7.19 8.89 10.38-2.18-5.14-3.92-9.97-5.32-14.53"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#32434d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -52.038 507.294)"
            d="M2389.75 2340.32c-.08.1-.17.22-.25.33l.37-.11-.12-.22"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#32434d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.778 507.518)"
            d="M2481.67 2231.33c-13.01 13.92-25.6 27.94-37.79 42.08 1.4 4.56 3.14 9.39 5.32 14.53-2.87-3.19-5.84-6.72-8.89-10.38-2.41 2.82-4.81 5.64-7.18 8.46 6.93 8.28 13.47 16.86 19.44 24.93l45.03-9.72-1.76-3.98c-12.47-28.18-12.75-47.65-14.17-65.92"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#32434d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.487 507.774)"
            d="M2582.83 2132.85c-1.8 1.6-3.61 3.21-5.4 4.81 4.77 1.25 9.06 2.51 12.44 3.6-2.54-2.84-4.9-5.66-7.04-8.41m-11.6 10.4c-12.1 10.96-23.94 22.03-35.52 33.2 3.99 14.84 4.91 33.48 19.26 59.74-11.86-10.8-25.47-25.98-39.11-36.13 2.27-2.52 3.5-5.11 3.8-7.89a1433.38 1433.38 0 0 0-16.48 16.64c.52-.19 1.04-.4 1.55-.64 19.72 12.06 39.61 32.62 55.88 50.58l43.85-14.14-2.15-3.79c-24.6-43.37-15.2-67.23-31.08-97.57"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#668e9d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.006 508.118)"
            d="M2719.99 2023.81c-6.55 4.66-13.06 9.37-19.51 14.1 3.67.84 7.17 2.78 10.07 5.94 6.3 6.88 8.02 12.89 4.2 19.78 15.56 6.84 32.23 18.58 46.2 26.45-25.83-29.08-26.64-50.71-39.68-64.93-.42-.46-.85-.91-1.28-1.34m-57.96 91.96c-9.75 0-19.81 2.79-28.74 9.19 18.31 16.01 43.05 32.14 59.24 32.14 3.76 0 7.05-.87 9.7-2.81 7.53-5.52 2.34-15.23-1.51-20.5-8.2-10.86-23.05-18.02-38.69-18.02"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#32434d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.153 507.944)"
            d="M2669.09 2102.27c-16.91 0-34.07 5.13-46.64 12.11-3.01-3.17-5.68-6.31-7.78-9.05-10.8 9.08-21.42 18.26-31.84 27.52 2.14 2.75 4.5 5.57 7.04 8.41 10.92 12.22 25.26 24.8 41.14 33.38 12.03 6.49 24.94 10.69 37.92 10.69 11.73 0 23.52-3.42 34.77-11.67 20.57-15.08 25.87-32.08 12.99-49.65-11.61-15.84-29.47-21.74-47.6-21.74m23.44 54.83c-16.19 0-40.93-16.13-59.24-32.14 8.93-6.4 18.99-9.19 28.74-9.19 15.64 0 30.49 7.16 38.69 18.02 3.85 5.27 9.04 14.98 1.51 20.5-2.65 1.94-5.94 2.81-9.7 2.81"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#32434d",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -50.877 508.263)"
            d="M2777.36 1984.76c-2.4 1.57-4.82 3.15-7.22 4.73 3.09.2 5.73.45 7.7.68l-.48-5.41m-19.76 13.06c-6.68 4.48-13.34 9.02-19.99 13.63-5.91 4.09-11.79 8.21-17.62 12.36.43.43.86.88 1.28 1.34 13.04 14.22 13.85 35.85 39.68 64.93-13.97-7.87-30.64-19.61-46.2-26.45 3.82-6.89 2.1-12.9-4.2-19.78-2.9-3.16-6.4-5.1-10.07-5.94a1659.15 1659.15 0 0 0-35.29 26.6c9.9 9.31 19.92 13.5 28.23 13.5 4.78 0 9-1.39 12.29-3.98 21.92 7.33 45.91 22.91 65.79 36.78l39.56-23.61-2.94-3.22c-32.85-35.82-30.04-60.82-50.52-86.16"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#ddd2b8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -50.377 505.953)"
            d="M2226.3 2647.15c293.16-206.26 621.48-371.46 1070.33-395.89l247.95 621.71-904.72 357.68-413.56-583.5"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#f4eddf",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -50.785 506.752)"
            d="M3255.06 2253.95c-19.78 276.55-274.53 495.36-586.06 495.36-151.24 0-289.1-51.6-393.22-136.3 271.49-183.84 576.24-328.84 979.28-359.06"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#fff6e3",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -49.748 504.509)"
            d="M3990.27 2992.45c-15.64 38.16-35.21 76.74-67.52 105.72-64.35 57.72-174.35 58.4-225.02 1.4 45.38 85.96 18.61 201.1-48.11 287.3-66.72 86.21-167.41 146.43-271.24 186.57-85.2 32.94-177.23 54.2-262.94 41.59-85.7-12.61-163.54-64.35-183.86-142.09-58.35 150.68-218.29 272.13-385.09 292.41-166.79 20.28-329.67-61.93-392.15-197.93-28.51-62.06-37.2-133.2-28-203.99 5.26-40.54 16.51-81.57 38.37-118.79 35.97-61.26 99.22-108.48 167.93-135.06 45.85-17.74 94.74-27.02 142.06-25.43 55.53 1.86 108.47 18.86 150.76 48.4l14.02-6.17c-25.44-240.41 446.77-443 590.66-283.68 35.79-118.86 191.64-236.22 320.98-256.72 44.14-6.99 87.83-1.75 130.43 4.46 70.36 10.26 140.88 23.43 202.39 52.99 61.51 29.56 113.86 77.4 132.78 140.43 19.72 65.7 1.55 140.2-26.45 208.59"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#6f818e",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.514 510.336)"
            d="M2496.93 1388.04c-86.08-107.04-159.42-224.72-255.16-323.2 53.43-18.75 116.74-5.57 158.24 32.95 26.05 24.19 43.02 56.35 59.49 87.86 35.51 67.92 71.6 137.59 81.96 213.53l306.37 399.73c-137.97-165.63-220.82-249.09-350.9-410.87"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#d6e3e8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -50.953 509.81)"
            d="m2898.06 1886.54-370.49-462.46a770.522 770.522 0 0 0 140.96-141.39c87.8 133.08 201.1 358.49 227.42 513.03 5.11 30.04 8.51 61.03 2.11 90.82"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#6f818e",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -55.382 510.61)"
            d="M1331.77 1428.12c-66.39 71.88-113.55 132.01-174.84 205.17l-10.91-6.65c49.41-64.13 108.04-136.6 162.94-213.1 50.47-70.34 256.11-398.5 312.23-352.78 43.1 35.11-206.34 277.38-289.42 367.36"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#d6e3e8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -56.38 509.348)"
            d="m1206.05 1675.9-.11-.08c-2.29 2.87-146.26 182.68-159.23 185.34l131.53-186.01c5.24-7.41 3.67-17.63-3.55-23.13l-6.53-4.97c-7.19-5.48-17.37-4.37-23.13 2.61-26.86 32.54-88.04 106.21-108.38 126.88 19.18-34.5 55.61-84.18 94.84-136.33 5.57-7.41 4.14-17.94-3.23-23.55-7.3-5.56-17.7-4.25-23.4 2.93l-141.255 178.05c-1.246-13.29 131.105-206.47 131.105-206.47l.1.03c4.94-5.28 46.63-19.29 81.57 9.53 37.83 31.21 34.35 69.12 29.67 75.17"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#6f818e",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.555 510.448)"
            d="M1987.67 1393.14c0 30.94-24.66 56.03-55.09 56.03-30.43 0-55.1-25.09-55.1-56.03 0-30.95 24.67-56.03 55.1-56.03s55.09 25.08 55.09 56.03"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#d6e3e8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.572 509.167)"
            d="M2281.41 1895.22c-82.39 98.49-211.07 156.73-340.98 156.89-130.54.15-260.15-57.48-349.72-152.86-89.87-95.69-138.57-265.82-135.21-396.03h946.25c.71 139.64-30.75 284.89-120.34 392"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#d6e3e8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.543 508.16)"
            d="M1974.84 2105.76c-4.73 5.08-13.17 4.93-20.71 4.89-13.49-.07-26.97-.13-40.46-.2-3.2-.01-6.63-.09-9.63-1.96-8.24-5.13-5.55-16.86-1.03-23.64 9.68-14.51 21.59-27.27 35.32-37.82 0 0 53.23 40.75 36.51 58.73"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#7f9aa0",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.555 510.252)"
            d="M1367.02 1515.43h1131.11c9.03 0 10.86-12.78 2.19-15.31l-221.28-64.53a1171.638 1171.638 0 0 0-615.32-11.07l-298.61 75.52c-8.92 2.26-7.29 15.39 1.91 15.39"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#fff",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.555 510.06)"
            d="M2498.13 1515.43c-386.45 10.3-763.99 10.84-1131.11 0-9.2 0-10.83-13.13-1.91-15.39l25.64-6.48h1087.07l22.5 6.56c8.67 2.53 6.84 15.31-2.19 15.31"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#e9f1f4",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.356 509.082)"
            d="M2281.41 1895.22c-82.39 98.49-211.07 156.73-340.98 156.89-127.78.15-254.57-55.17-343.88-146.96 5.66-143.1 157.81-286.13 369.46-335.67 169.97-39.78 332.32-8.49 425.22 70.47-15.51 93.48-49.41 183.06-109.82 255.27"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#e9f1f4",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.54 508.095)"
            d="M1954.13 2110.65c-13.49-.07-26.97-.13-40.46-.2-3.2-.01-6.63-.09-9.63-1.96-7.8-4.86-5.78-15.58-1.71-22.47 10.89-.47 22.01-.77 33.44-.77 13.37 0 26.42.34 39.04.99 3.77 7.37 4.8 14.38.03 19.52-4.73 5.08-13.17 4.93-20.71 4.89"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#fff",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.019 508.762)"
            d="M1976.01 1946.75c158.78-21.5 290.36-91 353.77-176.23-16.39 34.6-36.34 67.38-61.14 97.03-82.39 98.49-211.07 156.73-340.98 156.89-22.43.02-44.82-1.75-66.99-5.04 9.91-21.69 24.79-44.17 43.83-66.53 23.42-.87 47.3-2.84 71.51-6.12"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#e9f1f4",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -54.884 509.529)"
            d="M1544.9 1689.75c0 35.84 5.93 70.41 16.85 103.01-48.23-68.46-73.87-168.83-71.82-247.99h89.24c-21.96 44.27-34.27 93.31-34.27 144.98"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#fff",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -56.544 511.158)"
            d="M1089.24 1180.13c0 29.5-23.51 53.42-52.52 53.42s-52.525-23.92-52.525-53.42 23.515-53.41 52.525-53.41c29.01 0 52.52 23.91 52.52 53.41"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#fff",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -50.469 511.158)"
            d="M2913.28 1180.13c0 29.5-23.52 53.42-52.53 53.42-29 0-52.52-23.92-52.52-53.42s23.52-53.41 52.52-53.41c29.01 0 52.53 23.91 52.53 53.41"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#e8dfc8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -49.903 504.753)"
            d="M3574.27 2792.53c-48.47 123.77 45.33 276.23 209.51 340.53 8.16 3.19 16.33 6.03 24.51 8.67-43.01 1.01-84.59-12.94-110.56-42.16 13.86 26.26 20.92 55.25 22.23 85.3-89.1-131.76-341.76-284.36-538.01-229.1-234.04 65.89-354.16 328.46-247.96 515.86.97 1.71-11.51 17.65-12.77 19.96-54.58-104.52-202.06-150.58-360.77-150.58-206.22 0-373.47 124.98-374.54 279.46-12.22-16.61-22.94-34.26-31.57-53.05-28.51-62.06-45.26-139.13-36.06-209.92 5.27-40.54 16.51-81.57 38.37-118.79 35.97-61.26 99.22-108.47 167.93-135.06 45.86-17.74 94.74-27.01 142.07-25.43 55.52 1.86 112.18 15.08 154.46 44.62l14.01-6.17c-25.43-240.41 443.91-458.03 587.8-298.72 35.79-118.85 198.86-211.47 328.2-231.97 44.14-6.99 87.83-1.75 130.43 4.46 70.36 10.26 140.88 23.43 202.39 52.99 19.94 9.58 38.88 21.11 56.06 34.43-158.18-53.37-319.16-4.26-365.73 114.67"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#49413f",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.519 512.434)"
            d="M3374.62 863.551H511.824c-25.414 0-43.176-25.141-34.676-49.09l20.715-58.379a36.787 36.787 0 0 1 34.672-24.492H3353.9c15.58 0 29.47 9.808 34.67 24.492l20.72 58.379c8.5 23.949-9.26 49.09-34.67 49.09"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#423b39",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.512 506.442)"
            d="M1979.4 2621.61h-63.85v-55.34h63.85v55.34"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#423b39",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.751 506.442)"
            d="M1884.42 2593.94a9.18 9.18 0 0 1-9.18 9.18c-5.06 0-9.18-4.11-9.18-9.18 0-5.07 4.12-9.18 9.18-9.18a9.18 9.18 0 0 1 9.18 9.18"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#423b39",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -53.281 506.442)"
            d="M2025.96 2593.94a9.18 9.18 0 1 1-18.36 0 9.18 9.18 0 0 1 18.36 0"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#ddd2b8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -49.496 506.84)"
            d="M3047.99 2280.79c13.49-2.51 27.09-4.9 40.82-7.14l163.6 402.08c-62.72-120.3-143.7-277.11-204.42-394.94"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#ddd2b8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -50.074 506.677)"
            d="M2845.63 2330.58c14.13-4.36 28.41-8.54 42.8-12.59l220.92 414.27c-81.77-123.69-189.78-288.59-263.72-401.68"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#ddd2b8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -50.706 506.445)"
            d="M2655.54 2400.93c13.3-5.76 26.7-11.4 40.23-16.9l222.02 416.33c-81.23-122.87-188.35-286.41-262.25-399.43"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#ddd2b8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.847 505.996)"
            d="M2325.42 2580.13c10.2-6.62 20.45-13.18 30.75-19.67l213.13 336.87c-73.99-95.67-167.38-217.35-243.88-317.2"
          />
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "#ddd2b8",
              fillRule: "nonzero",
              opacity: 1,
            }}
            vectorEffect="non-scaling-stroke"
            transform="matrix(.13 0 0 -.13 -51.366 506.29)"
            d="M2481 2486.7c10.4-5.73 20.86-11.39 31.39-16.96l184.85 342.52c-65.55-98.09-147.82-222.16-216.24-325.56"
          />
        </g>
      </svg>
    );
  }
}
